import React from "react";
import { graphql } from 'gatsby';
import Database from "../../templates/DatabaseTemplate";

export default function DatabasePage({ data: { page } }) {
	return <Database page={page} locale="sv" />;
}

export const query = graphql`
	query {
		page: wpPage(slug: { eq: "databas-for-uppmuntrande-fraser" }) {
			slug
			title
			seo {
				metaDesc
				title
			}
			locale {
				locale
			}
			rohkaisuData {
				rohkaisuRepeater {
					rohkaisuTeko
				}
				rohkaisutRepeater {
					rohkaisulauseText
					rohkaisulauseSound {
						mediaItemUrl
					}
				}
				rohkaisuHeadingRepeater
			}
			content
			frontpageLinkTitle {
				styledTitle
			}
		}
	}
`;
